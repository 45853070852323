@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700;800;900&display=swap');

:root {
  --white: #ffffff;
  --primary-pink: #ff0d6b;
  --primary-dark-blue: #00122a;
  --tertiary-light-gray: #f6f6f9;
  --tertiary-navy: #042580;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
}

.embed-tool {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.embed-tool iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Montserrat', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  box-sizing: border-box;
}
a {
  color: unset;
}
.show-li {
  list-style: decimal !important;
}

.image-tool__caption,
.embed-tool__caption {
  display: none;
}

.mgR-15 {
  margin-right: 15px;
}
