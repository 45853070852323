.toast-container {
  display: flex !important;
  align-items: center;
  padding-left: 15px !important;
  border-radius: 4px !important;
  .message {
    margin-left: 10px;
    font-size: 1.3rem;
    font-weight: bold;
  }
}
