.popup_confirm {
  .ant-popover-arrow {
    display: none !important;
  }
  .ant-popover-content {
    border-radius: 4px;
    border: 1px solid #e7ebf4;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  }
  .ant-popover-inner-content {
    padding: 0 !important;
  }
}

.popup_confirm_navbar {
  margin-right: 30px;
  .ant-popover-arrow {
    display: none !important;
  }
  .ant-popover-content {
    border-radius: 4px;
  }
  .ant-popover-inner-content {
    padding: 0 !important;
  }

  &::before {
    content: '';
    width: 10px;
    height: 10px;
    background-color: #fff;
    position: absolute;
    top: -2px;
    right: 15px;
    transform: rotate(45deg);
  }
}

.mgR {
  &-15 {
    margin-right: 15px;
  }
  &-10 {
    margin-right: 10px;
  }
}
.mgB {
  &-20 {
    margin-bottom: 20px;
  }
  &-30 {
    margin-bottom: 30px;
  }
  &-45 {
    margin-bottom: 45px;
  }
}

.mgT {
  &-40 {
    margin-top: 40px;
  }
  &-20 {
    margin-top: 20px;
  }
  &-45 {
    margin-top: 45px;
  }
  &-10 {
    margin-top: 10px;
  }
}

.mgL {
  &-50 {
    margin-left: 50px;
  }
  &-40 {
    margin-left: 40px;
  }
  &-20 {
    margin-left: 20px;
  }
  &-45 {
    margin-left: 45px;
  }
  &-10 {
    margin-left: 10px;
  }
}

.pdL {
  &-40 {
    padding-left: 40px;
  }
  &-20 {
    padding-left: 20px;
  }
  &-45 {
    padding-left: 45px;
  }
  &-10 {
    padding-left: 10px;
  }
}

.pdR {
  &-40 {
    padding-right: 40px;
  }
  &-20 {
    padding-right: 20px;
  }
  &-10 {
    padding-right: 10px;
  }
  &-45 {
    padding-right: 45px;
  }
}

.pdB {
  &-40 {
    padding-bottom: 40px;
  }
  &-20 {
    padding-bottom: 20px;
  }
  &-45 {
    padding-bottom: 45px;
  }
  &-10 {
    padding-bottom: 10px;
  }
  &-0 {
    padding-bottom: 0px;
  }
}

.row {
  display: flex;
}

.download__file {
  font-size: 14px;
  background: #e7ebf4;
  padding: 8px 12px;
  border-radius: 4px;
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
}

th {
  text-align: left;
}
