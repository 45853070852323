.App {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}
html,
body {
  height: 100%;
}
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
._2rVjT,
._2W95M {
  background-color: #3e4c7d;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.disabledbutton {
  pointer-events: none;
  opacity: 0.6;
}

.ant-cascader {
  width: 100%!important;
}
