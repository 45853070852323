.root {
  align-items: center;
  align-self: flex-end;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 12px;
  color: rgb(79, 206, 93);
  cursor: pointer;
  display: flex;
  font-size: 2em;
  height: 64px;
  justify-content: center;
  margin-bottom: 20px;
  margin-right: 20px;
  margin-left: 20px;
  outline: transparent;
  user-select: none;
  width: 64px;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  position: absolute;
  bottom: 15px;
  right: 30px;
  position: relative;
}
.root::after {
  content: '';
  width: 10px;
  height: 10px;
  background-color: rgb(255, 0, 0);
  display: block;
  position: absolute;
  border-radius: 50%;
  right: 4px;
  top: 4px;
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 767px) {
  .root {
    margin-right: 0;
    width: 54px;
    height: 54px;
  }
}
